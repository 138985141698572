import React, { useState, useEffect } from "react"
import Select from "react-select"

import TableWithCheckboxes from "./TableWithCheckboxes"

import removeIcon from "../../assets/img/remove.svg"

const ConfiguracionDinamo = ({configuracionDinamo, groups, setNewData}) => {
	const [selectedGroupsFluctuaciones, setSelectedGroupsFluctuaciones] = useState([])
	const [selectedGroupsUCD, setSelectedGroupsUCD] = useState([])
	const [permiteMovimientos, setPermiteMovimientos]  = useState([])
	const [newEstacion, setNewEstacion] = useState("")

	useEffect(() => {
		setSelectedGroupsFluctuaciones(configuracionDinamo.grupo_envio_alerta_eds.fluctuaciones)
		setSelectedGroupsUCD(configuracionDinamo.grupo_envio_alerta_eds.ultimo_cierre_diario)
		setPermiteMovimientos(configuracionDinamo.permite_movimientos_tanque_camion)
	}, [configuracionDinamo])

	function save(){
		setNewData({
			configuracion_dinamo: {
				grupo_envio_alerta_eds: {
					fluctuaciones: selectedGroupsFluctuaciones,
					ultimo_cierre_diario: selectedGroupsUCD
				},
				permite_movimientos_tanque_camion: permiteMovimientos
			}
		})
	}

	function removeEds(key){
		const permiteMovimientosTmp = permiteMovimientos.slice()
		permiteMovimientosTmp.splice(key, 1)

		setPermiteMovimientos(permiteMovimientosTmp)
	}

	function changeEstacion(e){
		const value = e.target.value
		const isnum = /^\d+$/.test(value)
		if(!isnum && value.length > 0)
			return

		if(value.length <= 6)
			setNewEstacion(value)
	}

	function addEstacion(){
		const permiteMovimientosTmp = permiteMovimientos.slice()
		permiteMovimientosTmp.push(newEstacion)
		setPermiteMovimientos(permiteMovimientosTmp)
		setNewEstacion("")
	}

  return (
    <div className="selected-filter-container">
	    <div className="section">
	      <div className="section-title">Grupos de envios alertas EDS</div>
	      <div className="centered-container">
		      <TableWithCheckboxes
		        tableHeader="Fluctuaciones"
		        options={groups}
		        selectedOptions={selectedGroupsFluctuaciones}
		        setSelectedOptions={setSelectedGroupsFluctuaciones}
		      />

		      <TableWithCheckboxes
		        tableHeader="Ultimo cierre diario"
		        options={groups}
		        selectedOptions={selectedGroupsUCD}
		        setSelectedOptions={setSelectedGroupsUCD}
		      />
		    </div>
		  </div>
	    <hr />
	    <div className="section">
	      <div className="section-title">Permite movimientos tanque camion</div>
	      {
  				permiteMovimientos.map((eds, key) =>
  				<div className="info-text">
  					{eds}
  					<img className="remove-item" src={removeIcon} onClick={() => removeEds(key)} />
  				</div>
  			)}
  			<input placeholder="Ej: 12345" type="text" className="correo-input" value={newEstacion} onChange={(e) => changeEstacion(e)} />
	    	<button className="nuevo-correo-button" onClick={addEstacion}>Agregar</button>
		  </div>
		  <hr />

		  <button className="personal-estacio-modal-button" onClick={save}>
      	Guardar
      </button>
    </div>
  )
}

export default ConfiguracionDinamo
