import React, { useState, useEffect } from "react"
import Select from "react-select"
import Modal from "react-modal"

import { 
  showGreyBackground, 
  hideGreyBackground, 
  scrollTo
} from "../utils/windows"

import closeIcon from "../../assets/img/cross-icon.svg";
import removeIcon from "../../assets/img/remove.svg"

import {validateEmail} from "../utils/utils"
import { docModalStyles } from "../utils/styles.js";

const types = {
	"IMO": "IMO", 
	"jefe_comercial": "Jefe Comercial", 
	"jefe_operaciones": "Jefe Operaciones"
}

const PersonalEstacion = ({personal, setNewData}) => {
	const [displayModal, setDisplayModal] = useState(false)
	const [editKey, setEditKey] = useState()
	const [editInnerKey, setEditInnerKey] = useState()
	const [editItem, setEditItem] = useState()
	const [newEstacion, setNewEstacion] = useState("")
	const [newNombre, setNewNombre] = useState("")
	const [newCorreo, setNewCorreo] = useState("")

	function changeEstacion(e){
		const value = e.target.value
		const isnum = /^\d+$/.test(value)
		if(!isnum && value.length > 0)
			return

		if(value.length <= 6)
			setNewEstacion(value)
	}

	function addEstacion(){
		const editItemTmp = {...editItem}
		editItemTmp.estaciones.push(newEstacion)
		setEditItem(editItemTmp)
		setNewEstacion("")
	}

	function changeProperty(e, changeType){
		const editItemTmp = {...editItem}
		editItemTmp[changeType] = e.target.value
		setEditItem(editItemTmp)
	}

	function removeEstacion(key){
		const editItemTmp = {...editItem}
		editItemTmp.estaciones.splice(key, 1)
		setEditItem(editItemTmp)
	}

	function closeModal(){
		hideGreyBackground()
		setDisplayModal(false)
		setEditItem()
		setEditKey()
		setEditInnerKey()
	}

	function save(){
		const personalTmp = {...personal}
		if(editInnerKey === "NEW")
			personalTmp[editKey].push(editItem)
		else
			personalTmp[editKey][editInnerKey] = editItem

		setNewData({personal_estacion: personalTmp})
		closeModal()
	}

	function openModal(key, innerKey){
		setEditKey(key)
		setEditInnerKey(innerKey)
		setEditItem(JSON.parse(JSON.stringify(personal[key][innerKey])))
		showGreyBackground()
		setDisplayModal(true)
	}

	function createNew(key){
		setEditKey(key)
		setEditInnerKey()	
		setEditItem({nombre: "", email: "", estaciones: []})
		setEditInnerKey("NEW")
		showGreyBackground()
		setDisplayModal(true)
	}

  return (
    <div className="selected-filter-container">
	    {
	    	Object.keys(types).map((key) =>
	    		<>
	    			<div className="section-title section-title--left">
	    				{types[key]}
		    			<button className="nuevo-correo-button" onClick={() => createNew(key)}>Agregar Nuevo</button>
	    			</div>
	    			<table>
			        <tr>
			          <th>
			            <div className="th-data">
			              Nombre
			            </div>
			          </th>
			          <th>
			            <div className="th-data">
			              Email
			            </div>
			          </th>
			          <th>
			            <div className="th-data">
			              Estaciones
			            </div>
			          </th>
			          <th>
			            <div className="th-data">
			              Acciones
			            </div>
			          </th>
			         </tr>
		        	<tr className="table-separator-row-border-top"><td /></tr>

			        <tbody>
			    			{ personal[key].map((item, innerKey) =>
			    				<tr className="data">
			    					<td className="align-left">{item.nombre}</td>
			    					<td className="align-left">{item.email}</td>
			    					<td className="align-left estaciones-column">{item.estaciones.toString()}</td>
			    					<td className="align-left show-pointer" onClick={() => openModal(key, innerKey)}>Ver</td>
			    				</tr>
			    			)}
		    			</tbody>
	    			</table>
	    			<hr />
	    		</>	    		
	    )}

	    <Modal
        isOpen={displayModal}
        onRequestClose={closeModal}
        style={docModalStyles(350)}
      >
        <button className="modal__close-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        
				{editItem &&
	        <div className="documento-info-modal personal-estacion-info-modal">
	          <div className="title">Personal Estacion</div>
	          <div className="content">
	          	<div className="content--data">
	          		<div className="content--data--title">
		            	Nombre
	            	</div> 
	            	<input className="property-input" type="text" value={editItem.nombre} onChange={(e) => changeProperty(e, "nombre")} />
	            </div>
	            <div className="content--data">
		            <div className="content--data--title">
		            	Email
	            	</div> 
	            	<input className="property-input" type="text" value={editItem.email} onChange={(e) => changeProperty(e, "email")} />
	            </div>
	            <div className="content--data">
	            	<div className="content--data--title">
		            	Estaciones
	            	</div> 
	            	{ editItem.estaciones.map((estacion, key) =>
	            		<div className="modal-item">
	            			{estacion}
	            			<img src={removeIcon} onClick={() => removeEstacion(key)} />
	            		</div>
	            	)}
	            	<div className="new-estacion-section">
		            	<input className="property-input property-input-estacion" value={newEstacion} onChange={(e) => changeEstacion(e)} type="text" />
		            	<button className="personal-estacio-modal-button input-button" onClick={addEstacion}>
				            Agregar
				          </button>
				        </div>
            	</div>
	          </div>
	        </div>
				}

        <hr />
        <div className="modal__actions">
          <button className="personal-estacio-modal-button" onClick={save}>
          	{editInnerKey === "NEW"? "Crear": "Guardar"}
          </button>
          <button className="personal-estacio-modal-button cancel" onClick={closeModal}>
            Cancelar
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default PersonalEstacion
