import React, { useState, useEffect } from "react";

const TableWithCheckboxes = ({tableHeader, options, selectedOptions, setSelectedOptions}) => {
  function toggleRow(value){
    const tmpSelectedOptions = [...selectedOptions]

    if(selectedOptions.includes(value)){
      const index = selectedOptions.indexOf(value)
      tmpSelectedOptions.splice(index, 1)
      setSelectedOptions(tmpSelectedOptions)
    }
    else{
      tmpSelectedOptions.push(value)
      setSelectedOptions(tmpSelectedOptions)
    }    
  }

  return (
    <table className="tabla-copec-1 table-with-checkboxes multi-select-table">
      <thead>
        <tr>
          <th colSpan="2">{tableHeader}</th>
        </tr>
      </thead>
      <tr className="tabla-copec-1-separator"><td colSpan="2" /></tr>
      <tbody>
        {options &&
          options.map((option) => {
            return (
              <tr className={selectedOptions.includes(option.value)? 'selected': ''} key={option.value} onClick={() => toggleRow(option.value)}>
                <td className="table-with-checkboxes-checkbox-td">
                  <input 
                    className="table-with-checkboxes-checkbox" 
                    type="checkbox" 
                    checked={selectedOptions.includes(option.value)? true: false} />
                  </td>
                <td>{option.label}</td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  );
};

export default TableWithCheckboxes;
